import React from "react";
import { Col } from "react-bootstrap";

const CoronaWarning = ({warningKey}) => {
  const handleClose = () => {
    const model = document.getElementById("model-hide");
    if (model.style.display !== "none") {
      model.style.display = "none";
    } else {
      model.style.display = "block";
    }
  };

  return (
    <>
      <Col>
        <div id="model-hide" className="corona-warning">
          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={handleClose}
            className="close"
          >
            <b style={{position: 'absolute', top: '5px'}}>x</b>
          </div>
         <div dangerouslySetInnerHTML={{__html: warningKey.description}} />
        </div>
      </Col>
    </>
  );
};

export default CoronaWarning;