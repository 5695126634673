import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const SalesforceWebToCaseForm = () => {
  const [formData, setFormData] = useState({
    '00N1B00000B8U4J': '',
    '00N1B00000B8U4K': '',
    '00N4v00000C2nYW': '',
    '00N4v00000C2nYb': '',
    '00N4v00000C2nYc': '',
    '00N4v00000C2nYU': '',
    '00N4v00000C2nYa': '',
    '00N4v00000C2h5k': '',
    '00N4v00000C2h5p': '',
    '00N1B00000BDRwq': '',
    '00N4v00000C2nYX': '',
    '00N4v00000C2nYV': '',
    '00N1B00000BDRxP': '',
    '00N4v00000BqS6F': '',
    '00N3000000AY5Wa': '',
    '00N3000000AYgQ9': '',
    '00N3000000AY5WD': '',
    '00N4v00000C2nYY': '',
    '00N4v00000BqS6K': '',
    '00N4v00000BqSVA': '',
  });

  const captchaRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (response) => {
    console.log('reCAPTCHA response:', response);
    // You can handle the reCAPTCHA response if needed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the reCAPTCHA challenge has been completed
    const recaptchaResponse = captchaRef.current.getValue();

    if (!recaptchaResponse) {
      // If the reCAPTCHA challenge is not completed, alert the user or handle accordingly
      alert('Please complete the reCAPTCHA challenge.');
      return;
    }

    // Salesforce Web-to-Case endpoint URL
    const salesforceEndpoint =
      'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';

    try {
      const response = await fetch(salesforceEndpoint, {
        method: 'POST',
        body: new URLSearchParams({
          ...formData,
          'g-recaptcha-response': recaptchaResponse,
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      // Handle the Salesforce response
      const salesforceResponse = await response.json();
      console.log('Salesforce Web-to-Case response:', salesforceResponse);

      // Optionally, you can reset the form after successful submission
      setFormData({
        '00N1B00000B8U4J': '',
        '00N1B00000B8U4K': '',
        '00N4v00000C2nYW': '',
        '00N4v00000C2nYb': '',
        '00N4v00000C2nYc': '',
        '00N4v00000C2nYU': '',
        '00N4v00000C2nYa': '',
        '00N4v00000C2h5k': '',
        '00N4v00000C2h5p': '',
        '00N1B00000BDRwq': '',
        '00N4v00000C2nYX': '',
        '00N4v00000C2nYV': '',
        '00N1B00000BDRxP': '',
        '00N4v00000BqS6F': '',
        '00N3000000AY5Wa': '',
        '00N3000000AYgQ9': '',
        '00N3000000AY5WD': '',
        '00N4v00000C2nYY': '',
        '00N4v00000BqS6K': '',
        '00N4v00000BqSVA': '',
      });
    } catch (error) {
      console.error('Error submitting to Salesforce:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Field 1:
        <input
          type="text"
          name="00N1B00000B8U4J"
          value={formData['00N1B00000B8U4J']}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Field 1:
        <input
          type="text"
          name="00N1B00000B8U4K"
          value={formData['00N1B00000B8U4K']}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2nYW"
          value={formData['00N4v00000C2nYW']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2nYb"
          value={formData['00N4v00000C2nYb']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2nYc"
          value={formData['00N4v00000C2nYc']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2nYU"
          value={formData['00N4v00000C2nYU']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2nYa"
          value={formData['00N4v00000C2nYa']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2h5k"
          value={formData['00N4v00000C2h5k']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2h5p"
          value={formData['00N4v00000C2h5p']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N1B00000BDRwq"
          value={formData['00N1B00000BDRwq']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2nYX"
          value={formData['00N4v00000C2nYX']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2nYV"
          value={formData['00N4v00000C2nYV']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N1B00000BDRxP"
          value={formData['00N1B00000BDRxP']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000BqS6F"
          value={formData['00N4v00000BqS6F']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N3000000AY5Wa"
          value={formData['00N3000000AY5Wa']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N3000000AYgQ9"
          value={formData['00N3000000AYgQ9']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N3000000AY5WD"
          value={formData['00N3000000AY5WD']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000C2nYY"
          value={formData['00N4v00000C2nYY']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000BqS6K"
          value={formData['00N4v00000BqS6K']}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Field 1:
        <input
          type="text"
          name="00N4v00000BqSVA"
          value={formData['00N4v00000BqSVA']}
          onChange={handleInputChange}
        />
      </label>
     
      {/* Add other form fields as needed */}

      {/* ReCAPTCHA component */}
      <ReCAPTCHA
         sitekey={process.env.REACT_APP_SITE_KEY}
        ref={captchaRef}
        onChange={handleRecaptchaChange}
      />

      <button type="submit">Submit to Salesforce</button>
    </form>
  );
};

export default SalesforceWebToCaseForm;
