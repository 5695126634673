import React from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import { Oban_Whiskey_Domain } from "../../constant";
import { useLocation } from "react-router-dom";
import NewsletterModal from "./NewsletterModal";
function Footer() {
  let location = useLocation();
  return (
    <>
      <footer
        className={`footer ${location.pathname === "/distillery" ? "footerColor" : ""}  `}
      >
        <Container className="m-auto">
          <div className=" d-block d-md-flex justify-content-between ctaDiv text-center">
            <div className="cta footer-cta">
              Ready to try it?&nbsp;
              <Link to="/where-to-buy" className="cta-link">
                Find Oban near you.
              </Link>
            </div>
            <div className="deliver-bar d-flex justify-content-center mt-5 mt-md-0 align-items-center">
              <div className="deliver-cta d-sm-flex d-none">DELIVER TO ME</div>
              <a
                href="https://minibardelivery.com/store/brand/oban"
                className="delivery-service-link w-inline-block"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={`${Oban_Whiskey_Domain}/assets/images/minibar.png`}
                  alt="Minibar"
                  className="delivery-service ms-sm-4 ms-0"
                />
              </a>
              <a
                href="https://www.reservebar.com/scotch/oban"
                target="_blank"
                rel="noreferrer"
                className="delivery-service-link w-inline-block"
              >
                <Image
                  src={`${Oban_Whiskey_Domain}/assets/images/reserverbar.png`}
                  alt="ReserveBar"
                  className="delivery-service reservebar"
                />
              </a>
              <a
                href="https://drizly.com/liquor-brands/oban/b2012"
                target="_blank"
                rel="noreferrer"
                className="delivery-service-link w-inline-block"
              >
                <Image
                  src={`${Oban_Whiskey_Domain}/assets/images/drizzly.png`}
                  alt="Drizly"
                  className="delivery-service drizly"
                />
              </a>
            </div>
          </div>
          <div className="footer-content">
            <div id="footer" className="footer-bar"></div>
            {/* <div className="contact-bar w-clearfix p-3"></div>
            <p className="footer-contact-us" style={{ textAlign: "right" }}>
              Contact us:
              <Link to="mailto:oban@consumer-care.net" className="text-dark">
                oban@consumer-care.net
              </Link>
            </p> */}
          </div>
        </Container>
      </footer>
      {window.location.pathname !== '/contact-us' && <NewsletterModal /> }
      {/* <NewsletterModal /> */}
    </>
  );
}

export default Footer;
