import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

export default function NewsletterSubmit(props) {
  const handleClose = (event) => { props.isShowRegisterForm(false); event.preventDefault(); };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ marginTop: "10%" }}>
        <section className="newsLetter pb-5 mb-3">
          <Container className="mx-auto" style={{ maxWidth: "1000px" }}>
            <div className="stayTouch text-center">
              <h1 className="text-white-dark mb-4">Stay in Touch</h1>
              <p>
                Sign up today for exclusive updates from our team, direct from the distillery.
              </p>
            </div>

            <div className="justify-content-center text-center mt-md-5 mt-0 pt-5">
              <div className="stayTouch text-center">
                <h3 className="text-uppercase">{ props.apiResponse.success ? "Success!" : "Error!" }</h3>
                <sapn className="mb-2">
                 { props.apiResponse.success ? props.apiResponse.message : props.apiResponse.message }
                </sapn>
              </div>
              { props.apiResponse.success ? "" : <button onClick={handleClose} className='ms-2 mt-3
               backBtn' >Back</button> }
              {/* isShowRegisterForm */}
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}