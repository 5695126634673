import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Image } from "react-bootstrap";
import { Oban_Whiskey_Domain } from "../../constant";
const exploreJsonAPI = Oban_Whiskey_Domain+'/data/distilleryImages.json';

const DistillerySlider = () => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(false);

    const getData = async () => {
        await fetch(exploreJsonAPI).then(response => {
            return response.json();
        }).then(data => {
            setData(data);
            setStatus(true)
        }).catch(error => {
            
        });
    };

    useEffect(() => {
        getData();
    }, []);

    let settings = {
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        cssEase: 'linear',
        variableWidth: true,
        variableHeight: true,
        autoplay: true,
        arrows: true,  
        speed: 300,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    centerMode: false,
                    variableWidth: false,
                    variableHeight: false,
                }
            },
        ]
    };
    return (
        <div>
            <div className='sliderSect_img' style={{ textAlign: 'center', marginTop: '7em' }}>
                <Slider {...settings}>
                    {status &&
                        data.map((data, index) =>
                            <div key={index}>
                                <Image src={data.image} alt="Distillery" className="img-fluid" />
                            </div>
                        )
                    }
                </Slider>
            </div>
        </div>
    );

}

export default DistillerySlider;