import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Oban_Whiskey_Domain } from "../../constant";
import { Helmet } from 'react-helmet';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import FactsSection from "./FactsSection";


const Heritage = ({ heritageKey }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="heritage-page">
       <Helmet>
          <title>{heritageKey.title}</title>
          <meta name="description" content={heritageKey.metaDescription} />
          <meta property="og:title" content={heritageKey.title} />
          <meta
            property="og:description" 
            content={heritageKey.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${Oban_Whiskey_Domain + heritageKey.url}`}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={heritageKey.metaDescription} />
          <meta
            name="twitter:url"
            content={`${Oban_Whiskey_Domain + heritageKey.url}`}
          />
          <meta name="twitter:title" content={heritageKey.title} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
      <div className="height_100vh" style={{position: 'relative'}}>
        <ParallaxProvider>
          <Parallax
            translateY={['0', '0']}
            scale={[1, 1.5, 2]}
            className='heritage-image-bg'
          />
        </ParallaxProvider>
        <section className="heritage-image">
          <Container className="mx-auto my-0">
            <div className="hero-image heritage">
              <h1 className="mb-4">
                Two centuries of <br /> craftsmanship, authenticity, <br /> and exceptional
                taste.
              </h1>
              <h2 className="text-white kicker matter-sans">
                Our heritage flows through every drop.
              </h2>
            </div>
          </Container>
        </section>
      </div>

      <div className="clearfix"></div>

      <section className="heritage-section-tab">
        <div className="tab-block">
          <FactsSection />

        </div>
      </section>

      <section className="home-last-section">
        <Container>
          <Row>
          <Col md={6} style={{position: 'relative'}}>
            <div className="text-content">
              <h3 className="content-head"> Meet the whisky makers. </h3>
                <p>
                  The seven people who keep our distillery running are not just
                  employees. They are artisans who understand every step of the
                  whisky-making art, and guardians of a process that’s hardly
                  changed in more than 200 years.
                </p>
                <br />
                <p>
                  Their priceless expertise is handed down from generation to
                  generation, even within families. (Just ask Derek MacLean.)
                </p>
                <div className="cta-wrapper">
                  <Link
                    to="/people"
                    className="inline-link"
                  >
                    Learn about our people
                  </Link>
                </div>
              </div>
            </Col>
            <Col>
              <div className="footer-image home">
                <Image
                  src={`${Oban_Whiskey_Domain}/assets/images/team_imageCustom-1.jpg`}
                  alt="teams"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Heritage;