import React, { useEffect } from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import WhereToBuySection from "./WhereToBuySection";
import { Oban_Whiskey_Domain } from "../../constant";


const WhereToBuy = ({ whereToBuyKey }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="main where-to-buy">  
      <Helmet>
          <title>{whereToBuyKey.title}</title>
          <meta name="description" content={whereToBuyKey.metaDescription} />
          <meta property="og:title" content={whereToBuyKey.title} />
          <meta
            property="og:description"
            content={whereToBuyKey.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${Oban_Whiskey_Domain + whereToBuyKey.url}`}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={whereToBuyKey.metaDescription} />
          <meta
            name="twitter:url"
            content={`${Oban_Whiskey_Domain + whereToBuyKey.url}`}
          />
          <meta name="twitter:title" content={whereToBuyKey.title} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
        <section className="where-to-buy-section" style={{paddingTop: '80px'}}>
          <WhereToBuySection />
        </section>
          <section className="home-last-section">
           <Container>
            <Row>
              <Col md={6} style={{position: 'relative'}}>
                <div className="text-content">
                  <h3 className="content-head">You can find us where the Highlands meet the Islands.</h3>
                  <p>
                    The Oban Distillery Visitor Center — Winner of the
                    Association of Scottish Visitor Attractions’ Best Visitor
                    Experience award in 2017 — offers tours and tastings for a
                    range of budgets and interests.
                  </p>
                  <p>
                    Pay us a visit, hear our stories, and witness how one of the
                    world’s oldest and finest whiskies is produced first hand.
                  </p>
                  <div className="cta-wrapper">
                    <a target="_blank" rel="noreferrer" href="https://www.malts.com/en-gb/distilleries/oban/tours" className="inline-link" >Visit our distillery</a>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="footer-image home">
                  <Image
                    src={`${Oban_Whiskey_Domain}/assets/images/buy_bottom-p-500.jpeg`}
                    width="450px"
                    height="588px"
                    alt="home-bottom"
                    className="image-footer-responsive"
                  />
                </div>
              </Col>
            </Row>
           </Container>
          </section>
      </main>
    </>
  );
};

export default WhereToBuy;