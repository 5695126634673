import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Teams from "../slider/Teams";
import { Link } from "react-router-dom";
import DistillerySlider from "../slider/DistillerySlider";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from 'react-helmet';
import { Oban_Whiskey_Domain } from "../../constant";


const Distillery = ({ distilleryKey }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="distillery-page">
      <Helmet>
          <title>{distilleryKey.title}</title>
          <meta name="description" content={distilleryKey.metaDescription} />
          <meta property="og:title" content={distilleryKey.title} />
          <meta
            property="og:description"
            content={distilleryKey.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${Oban_Whiskey_Domain + distilleryKey.url}`}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={distilleryKey.metaDescription} />
          <meta
            name="twitter:url"
            content={`${Oban_Whiskey_Domain + distilleryKey.url}`}
          />
          <meta name="twitter:title" content={distilleryKey.title} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
        <div className="height_100vh">
          <ParallaxProvider>
            <Parallax
              translateY={["0", "0"]}
              scale={[1, 1.5, 2]}
              className="bg_firstSection"
            />
          </ParallaxProvider>
          <section className="firstSection">
            <div className="textContain">
              <Container>
                <h1 className="pb-5">
                  We’re one of the smallest <br /> scotch distilleries.
                </h1>
                <p className="kicker">
                  Each bottle comes from two stills. You can bet we take very
                  good care of both.
                </p>
              </Container>
            </div>
          </section>
        
        </div>

        <section className="secondSection">
          <Container style={{ margin: "auto" }}>
            <h3>Tour the distillery</h3>
            <p className="description">
              Nestling beneath the steep cliff that overlooks Oban, one of
              Scotland's oldest sources of Single Malt Scotch Whisky is just a
              short meander from the sea.
            </p>

            <Button
              type="Button"
              className="button"
              onClick={() =>
                openInNewTab(
                  "https://www.malts.com/en-gb/distilleries/oban/tours"
                )
              }
            >
              Book A Tour
            </Button>
          </Container>
        </section>

        <section style={{ background: "#faf6f3" }} className="thirdSection pb-0">
          <Container>
            <h2>The Oban story</h2>
            <p className="description">
              A small distillery built at the edge of the sea.
            </p>

            <Row className="mt120">
              <Col md={6}>
                <div className="videoDiv">
                  <video loop autoPlay muted>
                    <source
                      src="/assets/videos/homepage-hero.webm"
                      type="video/webm"
                    />
                    <source
                      src="https://player.vimeo.com/external/282337848.hd.mp4?s=4695cd0084a3b6437bc957c9cd0634e89f3b0213&profile_id=175"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </Col>
              <Col md={6}>
                <div className="ps-0 ps-md-2 mt-5 mt-md-0">
                  <h2 className="tertiary-heading">OUR HISTORY</h2>
                  <p className="mb-4">
                    When brothers Hugh and John Stevenson arrived at the
                    frontier of the Hebrides Islands in 1793, they found little
                    more than a natural harbor and a windswept view. Here they
                    opened the Oban Brewing Company, their ‘Cowbell Ale’ being
                    the first production.
                  </p>

                  <p className="mb-4">
                    They started distilling whisky the following year.
                    <br />
                  </p>

                  <p className="mb-4">
                    The business remained in the Stevenson family for three
                    generations, until local merchant Peter Cumpstie purchased
                    it in 1830. In 1880, the railroad steamed into Oban,
                    inaugurating a new age of improved communication and
                    transport.
                    <br />
                  </p>

                  <p className="mb-4">
                    The distillery has changed ownership over the years and
                    witnessed a bustling town grow up around it. But our fierce
                    commitment to excellence and tradition has been constant.
                    <br />
                  </p>

                  <p className="mb-4">
                    In 1989, Oban 14 Year Old was named one of six Classic Malts
                    representing the Western Highlands region.
                    <br />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="fourthSection mt-4 mt-md-0">
          <Container>
            <Row>
              <Col md={6} className="d-flex align-items-end">
                <div className="ps-0 ps-lg-5">
                  <h2 className="tertiary-heading">OBAN TODAY</h2>
                  <p>
                    Row just a few metres out to sea, and you can fit the entire
                    town of Oban within the single frame of a camera. And the
                    distillery is just a speck inside that image.
                  </p>

                  <p>
                    Oban is one of the smallest whisky makers in Scotland. And
                    that’s key to the character of our products. When expansion
                    isn’t an option and the volume we’re able to produce is
                    limited, we stand on quality, authenticity, and heritage.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="quoteDiv">
                  <div className="large-quote">
                    "The copper of the still has a <br /> conversation with the{" "}
                    <br /> whisky . . . The cleaner the <br /> copper and the
                    more you rest it, <br /> the fresher and cleaner that <br />{" "}
                    spirit is."
                  </div>
                  <span>— Kenny Gray, former Oban Distillery Manager</span>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="that-why">
          <Container>
            <h4 style={{ marginBottom: "2em" }}>That's why...</h4>

            <Row>
              <Col lg={4} md={6} xs={12} sm={6} className="p-md-4 p-3">
                <div className="component-number">1</div>
                <p>
                  We have just two small stills through which each drop passes,
                  enabling precise monitoring and control.
                </p>
              </Col>

              <Col lg={4} md={6} xs={12} sm={6} className="p-md-4 p-3">
                <div className="component-number">2</div>
                <p>
                  We ferment our malted barley for five full days for the
                  development of rich, nuanced flavour.
                </p>
              </Col>

              <Col lg={4} md={6} xs={12} sm={6} className="p-md-4 p-3">
                <div className="component-number">3</div>
                <p>
                  We rest our stills after every run, which protects purity and
                  taste.
                </p>
              </Col>

              <Col lg={4} md={6} xs={12} sm={6} className="p-md-4 p-3">
                <div className="component-number">4</div>
                <p>
                  We age our whisky as long as we do, with the understanding
                  that time is essential to its aroma, mouthfeel, and palate.
                </p>
              </Col>

              <Col lg={4} md={6} xs={12} sm={6} className="p-md-4 p-3">
                <div className="component-number">5</div>
                <p>
                  Our bottles don’t have to shout for attention from the shelf.
                  Our whisky &nbsp; — and our story — speak for themselves.
                </p>
              </Col>

              <Col lg={4} md={6} xs={12} sm={6} className="p-md-4 p-3">
                <div className="component-number">6</div>
                <p>
                  We’ve remained anchored to our process for over 200 years and
                  continue to value craftsmanship over speed.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="we-never">
          <Container>
            <h2 className="large-quote larger">
              "We’ve never had to . . . . make claims that we’re the peatiest,
              or the lightest, or the smoothest. The whisky speaks for itself."
            </h2>
            <p className="text-white">
              — Brendan McCarron, former Oban Distillery Manager
            </p>
            <Link to="/whiskies" className="cta underline">
              Explore our whisky
            </Link>
          </Container>
        </section>

        <section className="distillery-slider distillery-slider-parent">
          <Container fluid className="px-0">
            <Container>
            <h2>Tour the distillery</h2>
            <p className="description">
              See where the magic happens. For information about visiting us in
              person,&nbsp; 
              <a
                className="cta-link"
                href="https://www.malts.com/en-gb/distilleries/oban/tours"
                target="_blank" rel="noreferrer"
              >
                click here.
              </a>
            </p>
            </Container>
            <DistillerySlider />
          </Container>
        </section>

        <section
          style={{ background: "#faf6f3"}}
          className="teams-container"
        >
          <Container>
            <h2 className="mb-5 pb-4"> Meet our people</h2>
            <div>
              <Teams />
            </div>
            <div className='ps-md-5 ps-0 pt-5 pb-4'>
              <p className="description mt-30">
                Just seven people make our whisky.
                <br />
                Each is a master of their craft.
              </p>
              <Link to="/people">
                <Button className="learn-more-button button mt-2">Learn More</Button>
              </Link>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Distillery;
