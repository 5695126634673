import React, { useState, useEffect } from "react";
import Select from "react-select";

const MultiDropdown = ({ name, selectedOptions: initialSelectedOptions, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions || []);

  // Update state when parent component provides new selected options
  useEffect(() => {
    setSelectedOptions(initialSelectedOptions || []);
  }, [initialSelectedOptions]);

  const multiDropOptions = [
    { value: "Oban Single Malt Scotch – 14 Years Old", label: "Oban Single Malt Scotch – 14 Years Old" },
    { value: "Oban Single Malt Scotch – 18 Years Old", label: "Oban Single Malt Scotch – 18 Years Old" },
    { value: "Oban Little Bay Single Malt Scotch", label: "Oban Little Bay Single Malt Scotch" },
    { value: "Oban Distillers Edition Single Malt Scotch", label: "Oban Distillers Edition Single Malt Scotch" },
    // ...other options
  ];

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <div>
      <Select
        isMulti
        inputId={name}
        inputProps={{ name }}
        value={selectedOptions}
        onChange={handleChange}
        options={multiDropOptions}
        className="mb-3"
        placeholder="Select One Or More Product*"
      />
      <input type="hidden" name={name} value={selectedOptions.map(item => item.value)} />
      {/* JSON.stringify(selectedOptions) */}
    </div>
  );

};

export default MultiDropdown;
