import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Oban_Whiskey_Domain } from "../../constant";

export default function NotFound({notFoundKey}) {
  const jsonAPI = Oban_Whiskey_Domain + "/data/keyword.json";
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await fetch(jsonAPI, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        setStatus(true);
      });
  };


  return (
    <>
      <HelmetProvider>
        <Container fluid>
        <Helmet>
          <title>{notFoundKey.title}</title>
          <meta name="description" content={notFoundKey.metaDescription} />
          <meta property="og:title" content="" />
          <meta
            property="og:description"
            content=""
          />
          <meta property="og:type" content="" />
          <meta
            property="og:url"
            content={Oban_Whiskey_Domain}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content="" />
          <meta
            name="twitter:url"
            content={Oban_Whiskey_Domain}
          />
          <meta name="twitter:title" content="" />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
        <Row>
        <Col xs={{ span:8, offset:2}}>
        <div style={{ marginTop: "50px" }}>
            <br />
            <h1 style={{ color: "#000" }}>{status && data["404"].heading}</h1>
            <p>{status && data["404"].description}</p>
          </div>
        </Col>
       
        </Row>
        
        </Container>
      </HelmetProvider>
    </>
  );
}
