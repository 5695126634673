import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";

const HomePageVideoSlider = (props) => {
  var getVideos = props.videos;
  const [id, setId] = useState(0);
  const [currentVideo, setCurrentVideo] = useState("");
  const videoRef = useRef();
  const [videoText, setVideoText] = useState([]);

  var dynamicProgress1 = new Array(getVideos.length).fill(0);
  const [dynamicProgress, setDynamicProgress] = useState(
    ...Array(getVideos.length).fill(0)
  );

 
  // End Video
  const handleOnEnded = () => {
    setId((prev) => (id < 5 ? parseInt(prev) + parseInt(1) : 0));
  };

  useEffect(() => {
    videoRef?.current?.load();
  }, [currentVideo, id]);

  // Update Time
  const onTimeUpdate = (e) => {
    const progress = Math.round(
      (videoRef?.current?.currentTime / videoRef?.current?.duration) * 100
    );

    let newState = [...dynamicProgress1];
    newState[id] = progress;
    setDynamicProgress(newState);
  };

  // Delay 500
  setTimeout(() => {
    let video = getVideos[id];
    if (video) {
      let videoUrl = video["sources"][1]["src"];
      setCurrentVideo(videoUrl);
      setVideoText(getVideos[id]);
    }
  }, 500);

  // Select Items
  const items = (e) => {
    let sid = parseInt(e.target.id);
    setId(sid);
  };

  // Next Button
  const next = () => {
    setId(id === 5 ? 0 : parseInt(id) + parseInt(1));
  };

  // Provious Button
  const previous = () => {
    setId(id === 0 ? parseInt(5) : parseInt(id) - parseInt(1));
  };


  return (
    <div className="sliderContainer">
    
      <div style={{ height: "100%", minHeight: "600px" }}>
        {currentVideo && (
          <div>
            <div className="slider-text">
              <h3>{videoText.title}</h3>
              <p>{videoText.description}</p>
            </div>
            <video
              autoPlay={true}
              // controls
              muted={true}
              id="myVideo1"
              width="100%"
              ref={videoRef}
              onTimeUpdate={onTimeUpdate}
              crossOrigin="anonymous"
              onEnded={handleOnEnded}
              preload="metadata"
              playsInline
              className="full-video"
            >
              <source src={currentVideo} type="video/mp4" />
            </video>
            {/* <div className="arrows prev" onClick={previous}></div> */}
            <div className="slick-prev" onClick={previous}></div>
            {/* <div className="arrows next" onClick={next}></div> */}
            <div className="slick-next" onClick={next}></div>
          </div>
        )}

        <div className="pb-container progressBarContainer">
          {dynamicProgress &&
            dynamicProgress.map((val, index) => (
              <div key={index} className='d-contents'>
                
                  <span
                    id={index}
                    type="button"
                    className={`round-dot-home ${
                      id === index ? "home-video-active" : ""
                    }`}
                    onClick={(e) => items(e)}
                  ></span>
                  <ProgressBar
                    key={index}
                    completed={dynamicProgress[index]}
                    bgColor="#ffffff"
                    borderRadius="0"
                    customLabel=" "
                    height="1px"
                    transitionDuration={15}
                  />
              
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomePageVideoSlider;