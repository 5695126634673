import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap';
import Slider from 'react-slick';
import { Oban_Whiskey_Domain } from '../../constant';

const teamsJsonAPI = Oban_Whiskey_Domain + '/data/teams.json';

const Teams = () => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(false);

 
    const getData = async () => {
        await fetch(teamsJsonAPI, {
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson);
                setStatus(true);
            })

    }

    useEffect(() => {
        getData();
    },[]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings}>
                {
                    status && data.map((teamsKeyVal, index) =>
                        <div className="teams-member" key={index}>
                            <Image src={`${Oban_Whiskey_Domain}/${teamsKeyVal.image}`} alt={teamsKeyVal.name}  className='img-fluid'/>
                            <div className="middle">
                                <h4 >{teamsKeyVal.name}</h4>
                                <span >{teamsKeyVal.position}</span>
                            </div>
                        </div>
                    )
                }
            </Slider>
        </>
    )
}

export default Teams