import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Oban_Whiskey_Domain } from "../../constant";
import Teams from "../slider/Teams";
import OurValuesSection from "./OurValuesSection";
import { Image } from "react-bootstrap";
import NewPeopleVideos from "../slider/NewPeopleVideos";
// import NewPeopleVideos from "../slider/PeopleVideos";
import { Helmet } from "react-helmet";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

function People({ peopleKey }) {
  const exploreJsonAPI = Oban_Whiskey_Domain + '/data/newVideosPeople.json';
  const [data, setData] = useState([]);


  const PeopleVideo = async () => {
    await fetch(exploreJsonAPI, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        }
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            setData(myJson);
        })
}
useEffect(() => {
  window.scrollTo(0, 0);
  PeopleVideo();
}, []);


  return (
    <div className="people-container">
          <Helmet>
          <title>{peopleKey.title}</title>
          <meta name="description" content={peopleKey.metaDescription} />
          <meta property="og:title" content={peopleKey.title} />
          <meta
            property="og:description"
            content={peopleKey.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${Oban_Whiskey_Domain + peopleKey.url}`}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={peopleKey.metaDescription} />
          <meta
            name="twitter:url"
            content={`${Oban_Whiskey_Domain + peopleKey.url}`}
          />
          <meta name="twitter:title" content={peopleKey.title} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
      <div className="height_100vh pepleDiv" style={{position: 'relative'}}>
        <ParallaxProvider>
          <Parallax
            translateY={["0", "0"]}
            scale={[1, 1.5, 2]}
            className="bg_people-image"
          />
        </ParallaxProvider>
        <section className="people-image">
          <Container className="mx-auto my-0">
            <div>
              <h1 className="pb-4">
                There are seven employees at <br /> our distillery.{" "}
              </h1>
              <p className="kicker">
                {" "}
                Every drop of whisky is made by <br /> these 14 hands.
              </p>
            </div>
          </Container>
        </section>
      </div>

      {/* video Section */}
      <section className="video-section">
      
       <NewPeopleVideos videos={data} />
      </section>

      {/* Teams Section */}
      <section className="teams-container">
        <Container>
          <h2 className="mb-5 pb-4">The Team</h2>
          <div>
            <Teams />
          </div>
        </Container>
      </section>

      {/* Our Value Section     */}
      <section className="our-values">
        <OurValuesSection />
      </section>

      {/* section white */}
      <section className="home-last-section">
        <Container>
          <Row>
            <Col md={6} style={{position: 'relative'}}>
            <div className="text-content">
                <h3 className="content-head">
                  Honeyed spice, oak, and hints of salt air.
                </h3>
                <p>
                  Their priceless expertise is handed down from generation to
                  generation, even within families. (Just ask Derek MacLean.)
                </p>
                <div className="cta-wrapper">
                  <Link to="/whiskies" className="inline-link">
                    Explore our whiskies
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="footer-image home">
                <Image
                  src={`${Oban_Whiskey_Domain}/assets/images/people_footer_img.jpg`}
                  alt="people_footer_img "
                  width="450px"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default People;
