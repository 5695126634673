import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { useParams,Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { Oban_Whiskey_Domain } from "../../constant";
import Accordion from "react-bootstrap/Accordion";
import WhereToBuySection from "./WhereToBuySection";
import { Helmet } from "react-helmet";
import NotFound from "./404";
import { HelmetProvider } from "react-helmet-async";

const WhiskiesProductsComponent = () => {
  const params = useParams();
  const urlSlug = `${params.slug}`;
  const drinksJsonApi = Oban_Whiskey_Domain + "/data/whiskey.json";
  const executeScroll = () => myRef.current.scrollIntoView();

  const myRef = useRef(null);
  //set initial values empty to array

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // function locked for page load issue 
  //aditya dev
  //function for set data to variable
  // const getData = async () => {
  //     await fetch(drinksJsonApi, {
  //     headers: {
  //       "Content-type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then(function (response) {
  //       // console.log(response);
  //       return response.json();
  //     })
  //     .then(function (myJson) {
        
  //       if (loading) {
  //         return <p>Loading...</p>; // Loading state, display a loading message
  //       } setData(myJson);
  //     });
  // };

  // useEffect(() => {
  //   //  getData();
  //   getData().then(() => {
  //     setLoading(false); // Set loading to false after data is fetched
  //   });
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(drinksJsonApi, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        });
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data: ", error);
        // Handle errors if needed
      } finally {
        setLoading(false); // Set loading to false after data is fetched or if there's an error
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const goToTop = () => {
   
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  //filter by slug
  const data_array = data ? data : [];

  let elementPos = data_array
    .map(function (x) {
      return x.slug;
    })
    .indexOf(urlSlug);

  let objectFound = data_array[elementPos];

  let otherEdditionObj = {};
  if (objectFound) {
    Object.entries(data_array).map(([index, pdata]) =>
      urlSlug !== pdata.slug
        ? (otherEdditionObj[index] = {
          slug: pdata.slug,
          name: pdata.name,
          imageMedium: pdata.imageMedium,
          shortDescription: pdata.shortDescription,
        })
        : ""
    );
  }

  // filter with 1 condition
  const filtered = data.filter((data) => {
    return data.slug === urlSlug;
  });

  if (loading) {
    return <p>Loading...</p>; // Loading state, display a loading message
  }

  if ( filtered.length === 0) {
    const notFound = {
      404: {
        url: "/404",
        title: "Not Found",
        heading: "404 error",
        description:
          "Sorry, it appears the page you were looking for doesn’t exist anymore or may have moved.",
        keywords: "",
        canonicalTag: "https://www.obanwhisky.com/404",
        primaryImage: "",
        slug: "404",
      },
    };
    return (
      <div>
        <NotFound notFoundKey={notFound["404"]} />
      </div>
    );
  }

  

  return (
    <>
      <HelmetProvider>

        <Helmet>
          <title>{objectFound.title}</title>
          <meta name="description" content={objectFound.metaDescription} />
          <meta property="og:title" content={objectFound.title} />
          <meta
            property="og:description"
            content={objectFound.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${Oban_Whiskey_Domain + objectFound.url}`}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={objectFound.metaDescription} />
          <meta
            name="twitter:url"
            content={`${Oban_Whiskey_Domain + objectFound.url}`}
          />
          <meta name="twitter:title" content={objectFound.title} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
        <main className="product-details">
          <section className="container firstSection">
            <Row>
              <Col md={6} className="text-center">
                <Image
                  src={`${Oban_Whiskey_Domain}/assets/images/${objectFound && objectFound.image
                    }`}
                  alt="Oban-14-Years-Old"
                  className="image img-fluid"
                  width="180"
                />
              </Col>
              <Col md={6} className="col-50">
                <h1 className="hero-heading product text-dark">
                  <span className="preheader">OBAN</span>
                  <br />
                  {objectFound && objectFound.name}
                </h1>
                <p className="description">
                  {objectFound && objectFound.description}
                </p>
                <div className="awards-preview">
                  {objectFound &&
                    objectFound.awards.map((key, index) => (
                      <Image
                        key={index}
                        src={`${Oban_Whiskey_Domain}/assets/images/${objectFound && key.image
                          }`}
                        alt={key.award}
                        className="seal-image inline"
                      />
                    ))}
                </div>
                <div className="legal-drink-info">
                <p>OBAN Single Malt Scotch Whisky. 43% Alc/Vol.<br/> Imported by Diageo, New York, NY.</p>
                </div>
                <div className="d-flex mb-4">
                  {objectFound &&
                    objectFound.productDetails.Keywords.map((key, index) => (
                      <div className="flavor-tags" key={index}>
                        {key}
                      </div>
                    ))}
                </div>
                <Button className="button" size="lg" onClick={executeScroll}>
                  Buy Now
                </Button>
                <p></p>
                <div style={{ marginTop: "100px" }} className='productsTabs'>
                  <Accordion className="accordion-style" alwaysOpen>
                    {objectFound &&
                      Object.entries(objectFound.productDetails).map(
                        ([key, value]) =>
                          key !== "Keywords" ? (
                            <Accordion.Item eventKey={key} key={value}>
                              <Accordion.Header>{key}</Accordion.Header>
                              <Accordion.Body className="py-2 px-0">
                                <p className="flavor-description">{value}</p>
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : (
                            ""
                          )
                      )}
                  </Accordion>
                </div>
              </Col>
            </Row>
          </section>

          <section className="product__awards-reviews">
            <Container>
              <Row className="row">
              {(() => {
                if (params.slug !== "distillers-edition") {
                
                  return (
                    <Col md={6}>
                  <h2 className="component-heading margin-bottom">Awards</h2>
                  {objectFound &&
                    objectFound.awards.map((key, index) => (
                      <div className="d-flex award-img-des" key={index}>
                        <div className="me-4">
                          <Image
                            src={`${Oban_Whiskey_Domain}/assets/images/${objectFound && key.image
                              }`}
                            alt={key.award}
                            className="seal-image img-fluid"
                            width="60px"
                          />
                        </div>
                        <div className="award-information">
                          <h5>{key.award}</h5>
                          <div className="award-name">{key.competition}</div>
                        </div>
                      </div>
                    ))}
                </Col>
                  )
                }
              })()}
               
                <Col md={`${params.slug === 'distillers-edition' ? "12" : "6"}`}>
                  <h2 className="margin-bottom component-heading">Reviews</h2>
                  {objectFound &&
                    objectFound.reviews.map((key, index) => (
                      <div className="d-flex margin-bottom" key={index}>
                        <div className="tastemaker-headshot">
                          <Image
                            src={`${Oban_Whiskey_Domain}/assets/images/${key.image}`}
                            alt="Review"
                            className="seal-image"
                            width="60px"
                          />
                        </div>
                        <div className="award-information ps-3">
                          <h5>{key.who}</h5>
                          <div className="award-name">{key.where}</div>
                          <div className="quote">
                            <Image
                              src={`${Oban_Whiskey_Domain}/assets/images/quote_open.png`}
                              alt="Start Quote"
                              className="quote-open"
                              height="10px"
                            />
                            <blockquote className="block-quote">
                              {key.text}&nbsp;&nbsp;
                            </blockquote>
                            <Image
                              src={`${Oban_Whiskey_Domain}/assets/images/quote_close.png`}
                              alt="End Quote"
                              className="quote-close"
                              height="10px"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </Col>
              </Row>
            </Container>
          </section>

          <section ref={myRef} className="wheretobuy-section">
            <WhereToBuySection />
          </section>

          <section className="lastBottleSection">
            <Container>
              <h2 className="section-heading font-heading">Other editions</h2>
              <div className="related-products d-flex justify-content-between px-lg-5 px-0 mt-lg-0 mt-5">
                {objectFound &&
                  Object.entries(otherEdditionObj).map(([key, value]) => (
                    <div key={key}>
                      <Link
                        to={`/whiskies/${value.slug}`}
                        onClick={goToTop}
                        className="related-product"
                        data-ix="bottle-hover"
                      >
                        <Image
                          src={`${Oban_Whiskey_Domain}/assets/images/${value.imageMedium}`}
                          alt="Oban 18 Years Old"
                          className="related-product-image img-fluid"
                        />
                        {/* <div className="related-product-information d-lg-flex d-none"> */}
                        <div className="related-product-information">
                          <Image
                            src={`${Oban_Whiskey_Domain}/assets/images/tooltip.png`}
                            className="img-fluid lineImg  d-lg-flex d-none"
                            width="95px"
                          />
                          <div>
                            <h5>Oban {value.name}</h5>
                            <p className="related-product-description award-name">
                              {value.shortDescription}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
            </Container>
          </section>
        </main>
      </HelmetProvider>
    </>
  );
};

export default WhiskiesProductsComponent;