import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import OurWhisky from "./slider/OurWhisky";
import HomePageVideoSlider from "./slider/ExploreSlider";
import HeritageSection from "./Pages/HeritageSection";
import { Modal } from "reactstrap";
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from "react-helmet";
import { Oban_Whiskey_Domain } from "../constant";
const exploreJsonAPI = Oban_Whiskey_Domain + '/data/videosExplore.json';

export default function Home({homeKey}) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    ExplorVideo();
  }, []);

  const [data, setData] = useState([]);
  const ExplorVideo = async () => {
    await fetch(exploreJsonAPI, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        }
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            setData(myJson);
        })
}
  return (
    <>
      <main className="main index-page">
          <section className="hero">
          <Helmet>
          <title>{homeKey.title}</title>
          <meta name="description" content={homeKey.metaDescription} />
          <meta name="keywords" content={homeKey.Keywords} />
          <meta property="og:title" content={homeKey.title} />
          <meta
            property="og:description"
            content={homeKey.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={Oban_Whiskey_Domain}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={homeKey.metaDescription} />
          <meta
            name="twitter:url"
            content={Oban_Whiskey_Domain}
          />
          <meta name="twitter:title" content={homeKey.title} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
            <div className="bg-home-slider m-0">
              <div className="background-video">
                <ParallaxProvider>
                  <Parallax
                    translateY={['0', '0']}
                    scale={[1, 1.5, 2]}
                  >
                      <video
                        loop
                        autoPlay
                        muted
                        className="bgvid detect-gpu full-video"
                        id="bgvid"
                      >
                        <source
                          src={`${Oban_Whiskey_Domain + "/assets/videos/oban-homepage-revised-vimeo-872975228-hls-google_mediacdn_sep-4615.mp4"}`}
                          type="video/mp4"
                        />
                      </video>
                  </Parallax>
                </ParallaxProvider>
              </div>
              <div className="hero-video-trigger">
                <div className="play-button" onClick={openModal}></div>

                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  contentLabel="Example Modal"
                  className="video-modal"
                >
              <button className="exit-button" onClick={closeModal}>&#10005;</button>
                <video id="player" controls autoPlay  className="full-video">
                  <source
                    src={`${Oban_Whiskey_Domain + "/assets/videos/oban-homepage-revised-vimeo-872975228-hls-google_mediacdn_sep-4615.mp4"}`}
                    type="video/mp4"
                  />
                </video>
              </Modal>
                
              </div>
              <div className="hero-content index">
                <div className="container w-clearfix">
                  <div className="hero-heading-wrapper">
                    <h1 className="large-hero-heading">
                      Our story begins 
                      <span className="second-line"> in 1794.</span>
                    </h1>
                  </div>
                  <div className="hero-heading-wrapper w-clearfix">
                    <p className="kicker">
                      The world has changed, but our obsession with
                      craftsmanship, tradition, and uncompromising flavour has
                      not.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        
        {/* Whisky Section */}
        <section className="ourWhiskySection">
          <OurWhisky />
        </section>
        <section className="exploreSection">
        <div className="section-label dark" style={{left: 'auto', right: '30px'}}>EXPLORE</div>
          <HomePageVideoSlider  videos={data}/>
        </section>
        <section className="heritageSection">
          <div className="ps-lg-4 ps-md-2">
            <HeritageSection />
          </div>
        </section>
        <section className="home-last-section">
          <Container>
          <Row>
              <Col md={6} style={{position: 'relative'}}>
                <div className="text-content">
                  <h3 className="content-head">
                    We’ve never moved nor expanded.
                  </h3>
                  <p>
                    Built by brothers Hugh and John Stevenson, Oban whisky is
                    inextricably bound to its environment. Our water flows in
                    from a loch three miles away. Our barley travels from
                    Speyside. The distillery came first and a town rose up
                    around it.
                  </p>
                  <p>
                    We sit at the portal between the West Highlands and the
                    Hebrides Islands, a location renowned for its temperate
                    climate and rugged beauty.
                  </p>
                  <div className="cta-wrapper">
                    <Link to="/heritage" className="inline-link">
                      Explore our heritage
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="footer-image home">
                  <img
                    src="https://uploads-ssl.webflow.com/5b3c2a508d4e33445933fdcc/5b3d8c9306dea144486f6a82_home_bottom.jpg"
                    width="450"
                    height="auto"
                    alt="home-bottom"
                    className="img-fluid image-footer-responsive"
                  />
                </div>
              </Col>
            </Row>
          </Container>  
        </section>
      </main>
    </>
  );
}
