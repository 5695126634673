import React from "react";
import Navigation from "./Menu";
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import { useLocation } from "react-router-dom";
  const Header = ({pageData}) => {
  const location = useLocation();
  const checkRoute = location.pathname;
  if (checkRoute === "/where-to-buy" || checkRoute === "/where-to-buy/shop-online" 
  || checkRoute === "/where-to-buy/find-near-me" || checkRoute === "/newsletter") {
    const header = document.getElementsByTagName("header");
    if (header[0]) {
      header[0].classList.remove('fixedHeader');
      header[0].classList.add('absoluteHeader');
    }
    
  }else{
    const header = document.getElementsByTagName("header");
    if (header[0]) {
      header[0].classList.add('fixedHeader')
      header[0].classList.remove('absoluteHeader');
    }
  }
  return (
    <>
    <header className="fixedHeader">
        <Navigation pageKey={pageData} />
    </header>    
    </>
  );
};

export default React.memo(Header);  