import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { Oban_Whiskey_Domain } from "../../constant";
const whiskiesJsonAPI = Oban_Whiskey_Domain + "/data/whiskey.json";

const OurWhisky = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  let goLearnMore = (url) => {
    navigate(url);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await fetch(whiskiesJsonAPI, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        setStatus(true);
      });
  };

  const settings = {
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    fade: true,
    infinite: true,
  };

  return (
    <div id="ourwhisky-slider">
      <Container fluid className="px-4">
        <span className="section-lable absolute">OUR WHISKY</span>
        <Slider {...settings} className="vedioSlider">
          {status && data &&
            data.map((whiskeyVal, index) => (
              <div key={index}>
             
                <Row>
                  <Col md={6} className="wiskey-slider-text">
                    <div className="align-middle">
                      <h2>{whiskeyVal.name}</h2>
                      <p className="description">{whiskeyVal.description}</p>
                      <div className="buttons">
                        <div className="mb-3">
                          <Button
                            variant="dark"
                            onClick={() =>
                              goLearnMore(`/whiskies/${whiskeyVal.slug}`)
                            }
                            size="lg"
                            className="button"
                          >
                            LEARN MORE
                          </Button>
                        </div>
                        <span>
                          Ready to try it? &nbsp;
                          <Link to="/where-to-buy">Find Oban near you.</Link>
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="oban-image">
                      <Image
                        src={`${Oban_Whiskey_Domain}/assets/images/${whiskeyVal.imageMedium}`}
                        alt={whiskeyVal.name}
                        className="img-fluid"
                        height="800"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
        </Slider>
      </Container>
    </div>
  );
};

export default OurWhisky;
