import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free-solid";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

const WhereToBuySection = () => {
  const [lang, setLang] = useState(0);
  const [lat, setLat] = useState(0);
  const navigate = useNavigate();
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      const geocoder = new MapboxGeocoder({
        accessToken:
          "pk.eyJ1IjoibXdrcmllZ2VyIiwiYSI6ImNqcDA1NGQ4bDA0cjIzd3BpbGl5NzVvcXMifQ.2Cs5UE0BiZsh_wt36xHRoA",
        types: "postcode,address",
        placeholder: "Enter Location",
        countries: "us",
      });
      geocoder.addTo("#location");
      geocoder.on("result", (e) => {
        setLang(e.result.center[0]);
        setLat(e.result.center[1]);
      });
    }
  }, []);

  const handleSearch = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'customEvent',
      'eventCategory': 'WTB_Find_Near_Me',
      'eventAction': 'Find now',
      'eventLabel': 'Oban',
      'dimension14': 'Oban',
      'eventInteraction': true
    });

    let redirect_url = '/where-to-buy/find-near-me';
    if (lang && lat) {
      navigate(redirect_url + '?lat=' + lat + '&lng=' + lang);
    } else {
      navigate(redirect_url);
    }
  };

  const CallShopOnline = () => {
    navigate('/where-to-buy/shop-online');
  }
  return (
    <>
      <link
        rel="stylesheet"
        href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.css"
        type="text/css"
      />
      <div className="where-buy-section container">
          <div className="heading container px-0">
            <h1 className='text-dark'>Where to buy </h1>
          </div>
          <Row>
            <Col md={6} sx={12} className="find-section">
              <div className="inner">
                <h2 className="text-center">Find near me</h2>
                <div className="icon">
                  <FontAwesomeIcon icon={["fas", "fa-map-marker-alt"]} />
                </div>
                <div className="copy">
                  <p>
                    Use our store locator to find what’s available at a
                    retailer or restaurant/bar near you
                  </p>
                </div>
                <div className="formWrapper">
                  <div id="location" className="mb-4"></div>
                  <Button className="button" onClick={handleSearch} id='wtb-search' type="submit">Find Now</Button>
                </div>
              </div>
            </Col>
            <Col md={6} sx={12} className="shop-online-box mt-5 mt-md-0">
              <div className="inner">
                <h2 className="text-center mb-md-0 mb-5">Shop online</h2>
                <div className="icon">
                  <FontAwesomeIcon icon={["fas", "fa-shopping-cart"]} />
                </div>
                <div className="copy p-3 d-md-flex d-none">
                  <p></p>
                </div>
                <div className="linkWrapper mt-md-0 mt-5">
                  <Button className="button" onClick={CallShopOnline} type="submit">Shop Products</Button>
                </div>
              </div>
            </Col>
          </Row>
      </div>
    </>
  )
}

export default WhereToBuySection
