import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Oban_Whiskey_Domain } from '../../constant'

const ourValuesJsonApi = Oban_Whiskey_Domain + '/data/ourValues.json'

function OurValuesSection() {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        getData();
    },[])

    const getData = async () => {
        await fetch(ourValuesJsonApi, {
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson);
                setStatus(true);
            })
    }


    return (
        <>
            <Container>
                <h2 className='text-dark'>Our values</h2>
                <p className='description'>The qualities at the heart of our success — past, <br/> present, and, we believe, future.</p>

                <Row>
                    {
                       status && data.map((ourValueKeyApi, index) =>
                            <Col lg={4} md={6} sm={6} xs={12} key={index}>
                                <div className='textDiv py-md-3 py-sm-3 py-lg-3 py-2'>
                                    <h2 className='component-heading'>{ourValueKeyApi.title}</h2>
                                    <p className='p-values'>{ourValueKeyApi.description}</p>
                                </div>
                            </Col>
                        )
                    }

                </Row>
            </Container>
        </>
    )
}

export default OurValuesSection