import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Oban_Whiskey_Domain } from "../../constant";
import { Link } from "react-router-dom";
import HeritageAudio from "./HeritageAudio";
const obanFactJsonAPI = Oban_Whiskey_Domain + "/data/obanFacts.json";

const HeritageSection = () => {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const isMountedRef = useRef(false);
  const shuffle = data.sort(() => 0.3 - Math.random());

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      getData();
    }
  }, []);

  const getData = async () => {
    await fetch(obanFactJsonAPI, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        setStatus(true);
      });
  };

  const obanFact = shuffle.slice(0, 3);

  return (
    <>
      <Container fluid>
      <Row>
        <Col lg={6} md={12}>
      <HeritageAudio />
        </Col>
        <Col lg={6} md={12} className="responsive-spacing p-0">
          {status && obanFact.map((obanFact, index) => (
            <Link  to="#" key={index} className="our-heritage-link">
              <div className="d-flex align-items-center align-items-lg-start">
                <div className="description-flex">
                  <h5>{obanFact.title}</h5>
                  <p className="fact-description">{obanFact.fact}</p>
                </div>
                <div className="descImg">
                  <Image
                    src={`${Oban_Whiskey_Domain}/assets/images/facts/${obanFact.img}`}
                    alt={obanFact.category}
                    className="img-fluid"
                  />
                </div>
              </div>
            </Link>
          ))}
        </Col>
      </Row>
      </Container>
    </>
  );
};

export default HeritageSection;