import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Nav, Navbar } from "react-bootstrap";
import { ReactComponent as CloseMenu } from "../../assets/x.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { Oban_Whiskey_Domain } from "../../constant";
import useWindowDimensions from "./useWindowDimensions";

export default function Menu({ pageKey }) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [colorChange, setColorchange] = useState(false);
  const [colorOtherChange, setOtherColorchange] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [pageTitle, setPageTitle] = useState(false);
  const pathname = window.location.pathname;
  const { width } = useWindowDimensions();
  const changeNavbarColor = () => {
    if (
      pathname === "/" ||
      pathname === "/heritage" ||
      pathname === "/people"
    ) {
      if (window.scrollY >= 700) {
        setColorchange(true);

      } else {
        setColorchange(false);
      }
    } else {
      if (window.scrollY >= 100) {
        setOtherColorchange(true);
      } else {
        setOtherColorchange(false);
        console.log('19');
      }
    }

    if (width <= 479) {
      setColorchange(true);
      setOtherColorchange(true);
      
    }
  };

  useEffect(() => {
    if (width <= 479) {
      setColorchange(true);
      setOtherColorchange(true);
      console.log('17');
    }
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  window.addEventListener("scroll", changeNavbarColor);

  const listenToScroll = () => {
    let heightToHideFrom = 130;

    const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop;
    
    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else if (winScroll > 70) {
      setPageTitle(true);
      setIsVisible(true);
      console.log('1');
    }else if (winScroll > 100) {
      setPageTitle(true);
      setIsVisible(true);
      console.log('2');
    } else {
      setIsVisible(true);
      setPageTitle(false);
      console.log('13');
    }
  };

  return (
    <>
      <div className={colorOtherChange ? "nav-white" : ""}>
        <div className="">
          {pathname === "/" ||
          pathname === "/heritage" ||
          pathname === "/people" ? (
            <Navbar
              className={colorChange ? "navbar colorChange" : "navbar"}
              collapseOnSelect
              expand="sm"
            >
              <div style={{ lineHeight: 0 }}>
                <div className="mobile-menu" onClick={handleClick}>
                  {click ? (
                    <CloseMenu className="menu-icon" />
                  ) : (
                    <MenuIcon className="menu-icon" />
                  )}
                  <span className="ps-5 text-white">MENU</span>
                </div>
              </div>
              <div>
                <div className="logo">
                  <Link to="/">
                    <Image
                      src="/assets/images/oban_logo.png"
                      width="110"
                      height="auto"
                      className=""
                      alt="React Bootstrap logo"
                    />
                  </Link>
                </div>

                {(() => {
                  if (pathname === "/heritage" || pathname === "/people") {
                    return (
                      <div
                        id="hide"
                        className={` ${
                          pageTitle ? "page-name-light-title" : "page-name-light"
                        }`}
                      
                      >
                        {isVisible &&
                          pageKey[pathname.replace("/", "").trim()] &&
                          pageKey[pathname.replace("/", "").trim()].pageTitle}
                      </div>
                    );
                  }
                })()}
              </div>
              <div className="ms-auto">
                <div className="find-near-me d-flex justify-content-between">
                  <Link
                    to="/where-to-buy/find-near-me"
                    className="find-cta-link dark text-white"
                  >
                    Find near me
                  </Link>
                  <span>
                    <a
                      href="https://www.facebook.com/Oban/"
                      className="navigation-social-link facebook w-inline-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src={`${Oban_Whiskey_Domain}/assets/images/oban_fb.svg`}
                        alt="Facebook"
                        className="social-icon facebook"
                        width="auto"
                        height="18"
                      />
                    </a>
                    {/*  
                    <a
                      href="https://twitter.com/ObanScotch"
                      className="navigation-social-link w-inline-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src={`${Oban_Whiskey_Domain}/assets/images/oban_tw.svg`}
                        alt="Twitter"
                        className="social-icon"
                        width="auto"
                        height="18"
                      />
                    </a>
                    */}
                    <a
                      href="https://www.instagram.com/obanwhisky/"
                      className="navigation-social-link w-inline-block pe-0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src={`${Oban_Whiskey_Domain}/assets/images/oban_in.svg`}
                        alt="Instagram"
                        className="social-icon"
                        width="auto"
                        height="18"
                      />
                    </a>
                  </span>
                </div>
              </div>
            </Navbar>
          ) : (
            <Navbar
              className={
                colorOtherChange
                  ? "navbar navbarDark otherColorChange"
                  : "navbar navbarDark"
              }
              collapseOnSelect
              expand="sm"
            >
              <div>
                <div className="mobile-menu" onClick={handleClick}>
                  {click ? (
                    <CloseMenu className="menu-icon-dark" />
                  ) : (
                    <MenuIcon className="menu-icon-dark" />
                  )}
                  <span className="ps-5 text-white-dark">MENU</span>
                </div>
              </div>
              <div className="logoDiv">
                <div className="logo">
                  <Link to="/">
                    <Image
                      src="/assets/images/oban_logo_dark.png"
                      width="110"
                      height="auto"
                      className=""
                      alt="React Bootstrap logo"
                    />
                  </Link>
                </div>

                {(() => {
                  if (pathname === "/where-to-buy") {
                    return (
                      <div
                        id="hide"
                        className={` ${
                          pageTitle ? "page-name-dark " : "page-name-dark-title"
                        }`}
                      >
                        {isVisible &&
                          pageKey.whereToBuy &&
                          pageKey.whereToBuy.pageTitle}
                      </div>
                    );
                  } else if (pathname === "/whiskies/14-years-old") {
                    return (
                      <div
                        id="hide"
                        className={` ${
                          pageTitle ? "page-name-dark " : "page-name-dark-title"
                        }`}
                      >
                        {isVisible &&
                          pageKey.whiskies &&
                          pageKey.whiskies.fourteenYearsOld.pageTitle}
                      </div>
                    );
                  } else if (pathname === "/whiskies/18-years-old") {
                    return (
                      <div
                        id="hide"
                        className={` ${
                          pageTitle ? "page-name-dark " : "page-name-dark-title"
                        }`}
                      >
                        {isVisible &&
                          pageKey.whiskies &&
                          pageKey.whiskies.eighteenYearsOld.pageTitle}
                      </div>
                    );
                  } else if (pathname === "/whiskies/little-bay") {
                    return (
                      <div
                        id="hide"
                        className={` ${
                          pageTitle ? "page-name-dark " : "page-name-dark-title"
                        }`}
                      >
                        {isVisible &&
                          pageKey.whiskies &&
                          pageKey.whiskies.littleBay.pageTitle}
                      </div>
                    );
                  } else if (pathname === "/whiskies/distillers-edition") {
                    return (
                      <div
                        id="hide"
                        className={` ${
                          pageTitle ? "page-name-dark " : "page-name-dark-title"
                        }`}
                      >
                        {isVisible &&
                          pageKey.whiskies &&
                          pageKey.whiskies.distillersEdition.pageTitle}
                      </div>
                    );
                  } else if (pathname === "/where-to-buy/find-near-me" ) {
                    return (
                      <div
                        id="hide"
                        className={` ${
                          pageTitle ? "page-name-dark " : "page-name-dark-title"
                        }`}
                      >
                        {isVisible &&
                          pageKey.findNearMe &&
                          pageKey.findNearMe.pageTitle}
                      </div>
                    );
                  } else if (pathname === "/where-to-buy/shop-online") {
                    return (
                      <div
                        id="hide"
                        className={` ${
                          pageTitle ? "page-name-dark " : "page-name-dark-title"
                        }`}
                      >
                        {isVisible &&
                          pageKey.shopOnline &&
                          pageKey.shopOnline.pageTitle}
                      </div>
                    );
                  } else {
                    if (pathname === "/whiskies" || pathname === "/distillery" || pathname === "/newsletter") {
                      return (
                        <div
                          id="hide"
                          className={`${
                            pageTitle
                              ? "page-name-dark"
                              : "page-name-dark-title"
                          }`}
                        >
                          {isVisible &&
                            pageKey[pathname.replace("/", "").trim()] &&
                            pageKey[pathname.replace("/", "").trim()].pageTitle}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          id="hide"
                          className={`${
                            pageTitle
                              ? "page-name-dark-title"
                              : "page-name-dark"
                          }`}
                        >
                          {isVisible &&
                            pageKey[pathname.replace("/", "").trim()] &&
                            pageKey[pathname.replace("/", "").trim()].pageTitle}
                        </div>
                      );
                    }
                  }
                })()}
              </div>
              <div className="ms-auto">
                <div className="find-near-me d-flex justify-content-between">
                  <Link
                    to="/where-to-buy/find-near-me"
                    className="find-cta-link-dark dark text-white-dark"
                  >
                    Find near me
                  </Link>
                  <span>
                    <a
                      href="https://www.facebook.com/Oban/"
                      className="navigation-social-link facebook w-inline-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="/assets/images/fb_dark.png"
                        alt="Facebook"
                        className="social-icon facebook"
                        width="auto"
                        height="18"
                      />
                    </a>
                    
                     {/* 
                    <a
                      href="https://twitter.com/ObanScotch"
                      className="navigation-social-link w-inline-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src={"/assets/images/tw_dark.png"}
                        alt="Twitter"
                        className="social-icon"
                        width="auto"
                        height="18"
                      />
                    </a>
                    */}

                    <a
                      href="https://www.instagram.com/obanwhisky/"
                      className="navigation-social-link w-inline-block pe-0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="/assets/images/insta_dark.png"
                        alt="Instagram"
                        className="social-icon"
                        width="auto"
                        height="18"
                      />
                    </a>
                  </span>
                </div>
              </div>
            </Navbar>
          )}

          <Nav as="ul" className={click ? "nav-options active" : "nav-options"}>
            <div className="menu-items">
              <Link
                to="/"
                className="menu-item w--current"
                onClick={closeMobileMenu}
              >
                Home
              </Link>
              <Link
                to="/whiskies"
                className="menu-item"
                onClick={closeMobileMenu}
              >
                Whiskies
              </Link>
              <Link
                to="/distillery"
                className="menu-item"
                onClick={closeMobileMenu}
              >
                Distillery
              </Link>
              <Link
                to="/heritage"
                className="menu-item"
                onClick={closeMobileMenu}
              >
                Heritage
              </Link>
              <Link
                to="/people"
                className="menu-item"
                onClick={closeMobileMenu}
              >
                People
              </Link>
              <Link
                to="/where-to-buy"
                className="menu-item"
                onClick={closeMobileMenu}
              >
                Buy
              </Link>
              <Link
                to="/newsletter"
                className="menu-item"
                onClick={closeMobileMenu}
              >
                Join Now
              </Link>

              <Link
                to="/contact-us"
                className="menu-item"
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>

             
              
              {/* social media for mobile */}
              <div className="social-links-wrapper social-links-wrapper--mobile">
                {pathname === "/" ||
                pathname === "/heritage" ||
                pathname === "/people" ? (
                  <div>
                    <a
                      href="https://www.facebook.com/Oban/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-bar-link w-inline-block"
                    >
                      <img
                        src="/assets/images/oban_fb.svg"
                        alt="Facebook"
                        className="social-bar-icon facebook"
                      />
                    </a>
                     {/* 
                    <a
                      href="https://twitter.com/ObanScotch"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-bar-link w-inline-block"
                    >
                      <img
                        src="/assets/images/oban_tw.svg"
                        alt="Twitter"
                        className="social-bar-icon twitter"
                      />
                    </a>
                    */}
                    <a
                      href="https://www.instagram.com/obanwhisky/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-bar-link w-inline-block pe-0"
                    >
                      <img
                        src="/assets/images/oban_in.svg"
                        alt="Instagram"
                        className="social-bar-icon instagram"
                      />
                    </a>
                  </div>
                ) : (
                  <div>
                    <a
                      href="https://www.facebook.com/Oban/"
                      className="navigation-social-link facebook w-inline-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="/assets/images/fb_dark.png"
                        alt="Facebook"
                        className="social-bar-icon facebook"
                        width="auto"
                        height="18"
                      />
                    </a>
                     {/* 
                    <a
                      href="https://twitter.com/ObanScotch"
                      className="navigation-social-link w-inline-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src={"/assets/images/tw_dark.png"}
                        alt="Twitter"
                        className="social-bar-icon twitter"
                        width="auto"
                        height="18"
                      />
                    </a>
                    */}
                    <a
                      href="https://www.instagram.com/obanwhisky/"
                      className="navigation-social-link w-inline-block pe-0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="/assets/images/insta_dark.png"
                        alt="Instagram"
                        className="social-bar-icon instagram"
                        width="auto"
                        height="18"
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Nav>
        </div>
      </div>
    </>
  );
}