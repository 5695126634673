import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./responsive.css";
import Header from "./components/includes/Header";
import Footer from "./components/includes/Footer";
import Home from "./components/Home";
import Whiskies from "./components/Pages/Whiskies";
import WhereToBuy from "./components/Pages/WhereToBuy";
import ShopOnline from "./components/Pages/ShopOnline";
import FindNearMe from "./components/Pages/FindNearMe";
import NewsLetter from "./components/Pages/NewsLetter";
import WhiskiesProductsComponent from "./components/Pages/WhiskiesProductsComponent";
import Distillery from "./components/Pages/Distillery";
import People from "./components/Pages/People";
import Heritage from "./components/Pages/Heritage";
import { Oban_Whiskey_Domain } from "../src/constant";
import NotFound from "./components/Pages/404";
import CoronaWarning from "./components/Pages/CoronaWarning";
import ContactUs from "./components/Pages/ContactUs";
import SalesforceWebToCaseForm from "./components/Pages/SalesforceWebToCaseForm";
import ThankYou from "./components/Pages/ThankYou";
function App() {

  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const jsonAPI = Oban_Whiskey_Domain + "/data/keyword.json";
    const getData = async () => {
      await fetch(jsonAPI, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setData(myJson);
          setStatus(true);
        });
    };
    getData();
  }, []);



  return (
    <Router>
      <Header pageData={status ? data : ""} />
      <Routes>
        <Route path="*" element={<NotFound notFoundKey={status ? data["404"] : ""} />} />
        <Route path={`/whiskies/*`} element={<NotFound notFoundKey={status ? data["404"] : ""} />} />
        <Route
          exact
          path="/"
          element={<Home homeKey={status ? data.home : ""} />}
        />

        <Route
          exact
          path="/whiskies"
          element={<Whiskies whiskeyKey={status ? data.whiskies : ""} />}
        />
        <Route
          exact
          path="/where-to-buy"
          element={<WhereToBuy whereToBuyKey={status ? data.whereToBuy : ""} />}
        />
        <Route
          exact
          path="/where-to-buy/shop-online"
          element={<ShopOnline shopOnlineKey={status ? data.shopOnline : ""} />}
        />
        <Route
          path="/where-to-buy/find-near-me"
          element={<FindNearMe findNearMeKey={status ? data.findNearMe : ""} />}
        />
        <Route
          exact
          path="/newsletter"
          element={<NewsLetter newLetterKey={status ? data.newsletter : ""} />}
        />
        <Route
          path={`/whiskies/:slug`}
          element={<WhiskiesProductsComponent />}
        />
        <Route
          exact
          path="/distillery"
          element={<Distillery distilleryKey={status ? data.distillery : ""} />}
        />
        <Route
          exact
          path="/heritage"
          element={<Heritage heritageKey={status ? data.heritage : ""} />}
        />
        <Route
          exact
          path="/people"
          element={<People peopleKey={status ? data.people : ""} />}
        />

        <Route
          path={`/thank-you`}
          element={<ThankYou />}
        />
        <Route
          exact
          path="/contact-us"
          element={<ContactUs peopleKey={status ? "ContactUs" : ""} />}

        />

        <Route
          exact
          path="/contact"
          element={<SalesforceWebToCaseForm peopleKey={status ? "ContactUs" : ""} />}

        />
      </Routes>

      <Footer exact={true} />
      <CoronaWarning warningKey={status ? data.coronaWarning : ""} />

    </Router>
  );
}

export default App;
