import React, { useEffect, useRef, useState } from "react";
import { Modal, Col, Row, Form, Button } from "react-bootstrap";
import "../../index.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { Oban_Whiskey_Domain } from "../../constant";
import NewsletterSubmitModal from "./NewsletterSubmitModal";

export default function NewsletterModal() {
  const parse = require("html-react-parser");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      document.body.style.overflow = "unset";
    }, 1500);
  };
  const [showModal, setShowModal] = useState(false);
  const modelRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation(); // React Hook
  const checkRoute = location.pathname;
  const [responseApi, setResponseApi] = useState({});
  const [gToken, setGToken] = useState("");
  const [isGtoken, setIsGToken] = useState(false);
  let routUrl = Oban_Whiskey_Domain + checkRoute;

  const date = new Date().toISOString().split("T")[0];

  function minDate() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 125);
    return date.toISOString().split("T")[0];
  }

  function differenceInYears(inputDate) {
    if (new Date().getFullYear() < inputDate.getFullYear()) {
      return 0;
    }
    const ageDifMs = Date.now() - inputDate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from current and input date
    const ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970); //calculate age
    return ageInYears;
  }

  function invalidDateCheck(inputDate) {
    const ageDifMs = Date.now() - inputDate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from current and input date
    const ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970); //calculate age
    return ageInYears;
  }

  const newsletterSchema = Yup.object().shape({
    CP_FirstName: Yup.string()
      .max(50, "First Name is Too Long!")
      .matches(
        /^[a-zA-Z]+$/,
        "First Name cannot contain white space and special character"
      ).required("First Name is required."),
    CP_LastName: Yup.string()
      .max(80, "Last Name is Too Long!")
      .matches(
        /^[a-zA-Z]+$/,
        "Last Name cannot contain white space and special character"
      )
      .required("Last Name is required."),
    CP_DOB: Yup.date()
      .required("DOB is required.")
      .test(
        "DOB",
        "You must be at least 21 years old to enter.",
        function (value) {
          return differenceInYears(new Date(value)) >= 20;
        }
      )
      .test("DOB", "Invalid Date.", function (value) {
        return invalidDateCheck(new Date(value)) < 125;
      }),
    PR_4_324_1_freetext: Yup.string()
      .matches(
        /^[0-9]+$/,
        "Please provide valid zip code!"
      )
      .required("Zip Code is required."),
    CP_EmailId: Yup.string()
      .email("Please enter valid email address.")
      .required("Email is required."),
    /*tc: Yup.bool().oneOf(
      [true],
      "Please tick this box if you want to proceed."
    ),*/
    PR_1_64_1: Yup.bool().oneOf(
      [true],
      "Please tick this box if you want to proceed."
    ),
    PR_1_64_2: Yup.bool(),
    G_Token: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      PR_4_367_1_freetext: "",
      CP_FirstName: "",
      CP_LastName: "",
      CP_DOB: "",
      CP_EmailId: "",
      PR_4_324_1_freetext: "",
      PR_1_64_1: false,
      PR_1_64_2: false,
      tc: false,
      CP_WebsiteUrl: routUrl,
      HD_Consumerid: "",
      G_Token: ""
    },
    validationSchema: newsletterSchema,
    onSubmit: async (values, { resetForm }) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Ld_GsYlAAAAAG6sAH5EPuAWZDmoOJUnerI3x7Oi', { action: 'submit' }).then(token => {
          // submitData(token);
          setGToken(token);
          setIsGToken(true);
        });
      });
      var dataForm = {
        ContactKey: values.CP_EmailId,
        EventDefinitionKey: "APIEvent-a5e397d8-3580-77b4-40c7-2f6ee5437c7f",
        Data: {
          firstName: values.CP_FirstName,
          lastName: values.CP_LastName,
          dateOfBirth: values.CP_DOB,
          emailAddress: values.CP_EmailId,
          postCode: values.PR_4_324_1_freetext,
          emailable: values.PR_1_64_1 === true ? true : false,
          partnerOptIn: values.PR_1_64_2 === true ? true : false,
          country: "US",
          privacyPolicyVersion: "14.0",
          subscriberKey: values.CP_EmailId,
          brand: "Oban",
          brandAbbreviation: "DFT",
          G_Token: "",

        }
      };

      if (isGtoken) {
        dataForm.Data.G_Token = gToken;
      }

      let eHheader = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
        crossdomain: true,
        methods: ['POST', 'GET', 'PATCH', 'DELETE', 'OPTIONS'],
        allowedHeaders: ['Content-Type', 'Authorization'],
      }

      axios
        .post("api/request.php", dataForm, eHheader)
        .then(function (result) {
          if (result.data.eventInstanceId) {
            result.data.success = true;
            result.data.message = "Thank you for singing up for our newsletter.";
            setResponseApi(result.data);
          } else {
            result.data.success = false;
            setResponseApi(result.data);
          }
          
          setShow(false);
          setShowModal(true);
        })
        .catch(function (e) {
          setResponseApi(e);
          setShow(false);
          setShowModal(true);
        });
    },
  });

  const newsModal = () => {
    let visited = localStorage["newsletterModal"] || false;
    let today = new Date();
    // localStorage["newsletterModal"] = new Date("Tue Jan 11 2022 13:10:43 GMT+0530 (India Standard Time)");
    var storageDate = new Date(localStorage["newsletterModal"]);
    if (!window.location.pathname.includes("/newsletter")) {
      if (visited) {
        if (
          (today.getTime() - storageDate.getTime()) / (1000 * 3600 * 24) >
          30
        ) {
          setTimeout(() => {
            setShow(true);
            localStorage["newsletterModal"] = new Date();
          }, 7000);
        }
      } else {
        setTimeout(function () {
          localStorage["newsletterModal"] = new Date();
          setShow(true);
        }, 7000);
      }
    }
  };

  const handleCallback = (childData) => {
    setShow(childData);
  };

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    }

    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6Ld_GsYlAAAAAG6sAH5EPuAWZDmoOJUnerI3x7Oi`, function () {
      console.log("Script loaded!");
    });
  }, [])

  useEffect(() => {
    newsModal();
    if (isLoaded) {
      modelRef.current.dialog.removeAttribute("tabindex");
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Ld_GsYlAAAAAG6sAH5EPuAWZDmoOJUnerI3x7Oi', { action: 'submit' }).then(token => {
          // submitData(token);
          // console.log(token);
          setGToken(token);
          setIsGToken(true);
        });
      });
    }
  }, [isLoaded, isGtoken]);

  return (
    <>
      {showModal && (
        <NewsletterSubmitModal
          isShowModal={showModal}
          apiResponse={responseApi}
          registerModel={handleCallback}
        />
      )}
      <Modal
        size="lg"
        onEntered={(e) => setIsLoaded(true)}
        show={show}
        onHide={handleClose}
        className="newsLetter"
        ref={modelRef}
        centered
      >
        <Modal.Header className="border-0 pb-0 m-auto pt-0">
          <div className="stayTouch text-center">
            <h3 className="colorRed mt-3">Stay in Touch</h3>
            <p>
              Sign up today for exclusive updates from our team, direct from the
              distillery.
            </p>
          </div>
          <button onClick={handleClose} type="button" className="btn closeBtn">
            ✖
          </button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="justify-content-center">
            <Form
              className="touchForm"
              id="cidbNewsletter"
              data-action="newsletter-modal"
            >
              <Row>
                <Form.Group className="input" controlId="HD_PromoCode">
                  <Form.Control
                    type="hidden"
                    name="HD_PromoCode"
                    value="DNA107FCM0721WA0015"
                  />
                </Form.Group>
                <Form.Group
                  className="input"
                  controlId="HD_Consumerid"
                  value=""
                >
                  <Form.Control type="hidden" name="HD_Consumerid" />
                </Form.Group>
                <Form.Group className="input">
                  <Form.Control
                    type="hidden"
                    name="action"
                    value="newsletter-modal"
                  />
                </Form.Group>

                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3 input" controlId="CP_FirstName">
                    {/* <Form.Label>
                      First Name*
                    </Form.Label> */}
                    <Form.Control
                      name="CP_FirstName"
                      value={formik.values.CP_FirstName}
                      maxLength="50"
                      placeholder="First Name*"
                      onChange={formik.handleChange("CP_FirstName")}
                    />
                    {formik.errors.CP_FirstName &&
                      formik.touched.CP_FirstName ? (
                      <span>{formik.errors.CP_FirstName}</span>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3 input" controlId="CP_LastName">
                    {/* <Form.Label>
                      Last Name*
                    </Form.Label> */}
                    <Form.Control
                      name="CP_LastName"
                      value={formik.values.CP_LastName}
                      maxLength="50"
                      onChange={formik.handleChange("CP_LastName")}
                      placeholder="Last Name*"
                    />
                    {formik.errors.CP_LastName && formik.touched.CP_LastName ? (
                      <span>{formik.errors.CP_LastName}</span>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3 input" controlId="CP_DOB">
                    {/* <Form.Label>
                      Date of Birth*
                    </Form.Label> */}

                    <Form.Control
                      name="CP_DOB"
                      className={formik.values.CP_DOB ? "rem-placeholder" : ""}
                      type="date"
                      value={formik.values.CP_DOB}
                      onChange={formik.handleChange("CP_DOB")}
                      // onBlur={(e) => {
                      //   callFunction(e.target);
                      // }}
                      placeholder="dd-mm-yyyy*"
                      max={date}
                      min={minDate()}
                    />
                    {formik.errors.CP_DOB && formik.touched.CP_DOB ? (
                      <span>{formik.errors.CP_DOB}</span>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Form.Group
                    className="mb-3 input"
                    as={Col}
                    controlId="PR_4_324_1_freetext"
                  >
                    {/* <Form.Label>
                      Zip Code*
                    </Form.Label> */}
                    <Form.Control
                      name="PR_4_324_1_freetext"
                      maxLength="5"
                      placeholder="Zip Code*"
                      value={formik.values.PR_4_324_1_freetext}
                      onChange={formik.handleChange("PR_4_324_1_freetext")}
                    />
                    {formik.errors.PR_4_324_1_freetext &&
                      formik.touched.PR_4_324_1_freetext ? (
                      <span>{formik.errors.PR_4_324_1_freetext}</span>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col lg={12} md={12} xs={12}>
                  <Form.Group
                    className="mb-3 input"
                    as={Col}
                    controlId="CP_EmailId"
                  >
                    {/* <Form.Label>
                      Email*
                    </Form.Label> */}

                    <Form.Control
                      name="CP_EmailId"
                      type="email"
                      value={formik.values.CP_EmailId}
                      maxLength="100"
                      onChange={formik.handleChange("CP_EmailId")}
                      placeholder="Email*"
                    />
                    {formik.errors.CP_EmailId && formik.touched.CP_EmailId ? (
                      <span>{formik.errors.CP_EmailId}</span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="input" controlId="HD_EmailType">
                    <Form.Control
                      name="HD_EmailType"
                      type="hidden"
                      value="1"
                      runat="server"
                    />
                  </Form.Group>
                </Col>

                <Form.Group className="input">
                  <Form.Check
                    className={
                      formik.errors.PR_1_64_1 && formik.touched.PR_1_64_1
                        ? "error"
                        : ""
                    }
                    name="PR_1_64_1"
                    value={formik.values.PR_1_64_1}
                    onChange={formik.handleChange("PR_1_64_1")}
                    type="checkbox"
                    checked={formik.values.PR_1_64_1}
                    id="PR_1_64_1"
                    label={parse(
                      "* Tick here if you would like us to use your email to keep you informed about products, services and events from Oban & other Diageo brands including <a href='https://www.thebar.com/en-gb' target='_blank' style='color: black; text-decoration: underline' >TheBar.com</a>. You can unsubscribe at any time. By signing up, you accept Diageo's <a  href='https://footer.diageohorizon.com/dfs/assets/www.obanwhisky.com/TnC_uen.html?locale=uen-us' target='_blank' style='color: black; text-decoration: underline'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.obanwhisky.com/PrivacyPolicy_uen.html?locale=uen-us' style='color: black; text-decoration: underline' target='_blank' >Privacy & Cookie Notice</a>."
                    )}
                  />
                </Form.Group>

                {/*<Form.Group
                  className="input"
                  as={Col}
                  controlId="HD_PR_1_64_1_Brand"
                >
                  <Form.Control
                    name="HD_PR_1_64_1_Brand"
                    type="hidden"
                    value="30"
                    runat="server"
                  />
                </Form.Group>
                <Form.Group
                  className="input"
                  as={Col}
                  controlId="HD_PR_1_64_1_Comm"
                >
                  <Form.Control
                    name="HD_PR_1_64_1_Comm"
                    type="hidden"
                    value="1"
                    runat="server"
                  />
                </Form.Group>

                <Form.Group className="input eMargin">
                  <Form.Check
                    className={
                      formik.errors.PR_1_64_2 && formik.touched.PR_1_64_2
                        ? "error"
                        : ""
                    }
                    name="PR_1_64_2"
                    value={formik.values.PR_1_64_2}
                    type="checkbox"
                    id="PR_1_64_2"
                    checked={formik.values.PR_1_64_2}
                    onChange={formik.handleChange("PR_1_64_2")}
                    label="Yes, I would like to receive promotions and special offers for gifting sprits, cocktail kits and barware from TheBar.com by email."
                  />
                </Form.Group>
                <Form.Group
                  className="input"
                  as={Col}
                  controlId="HD_PR_1_64_2_Brand"
                >
                  <Form.Control
                    name="HD_PR_1_64_2_Brand"
                    type="hidden"
                    value="16"
                    runat="server"
                  />
                </Form.Group>
                <Form.Group
                  className="input"
                  as={Col}
                  controlId="HD_PR_1_64_2_Comm"
                >
                  <Form.Control
                    name="HD_PR_1_64_2_Comm"
                    type="hidden"
                    value="1"
                    runat="server"
                  />
                </Form.Group>

                <Form.Group className="input">
                  <Form.Check
                    className={
                      formik.errors.tc && formik.touched.tc ? "error" : ""
                    }
                    name="tc"
                    value={formik.values.tc}
                    type="checkbox"
                    checked={formik.values.tc}
                    id="tc"
                    onChange={formik.handleChange("tc")}
                    label={parse(
                      "Tick here if you would like us to use your email to keep you informed about products, services and events from Oban & other Diageo brands including TheBar.com. You can unsubscribe at any time. By signing up, you accept Diageo's <a  href='https://footer.diageohorizon.com/dfs/assets/www.obanwhisky.com/TnC_uen.html?locale=uen-us' target='_blank' style='color: black; text-decoration: underline'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.obanwhisky.com/PrivacyPolicy_uen.html?locale=uen-us' style='color: black; text-decoration: underline' target='_blank'>Privacy & Cookie Notice</a>."
                    )}
                  />
                  {formik.errors.tc && formik.touched.tc ? (
                    <span>
                      {formik.errors.tc}
                    </span>
                  ) : null}
                </Form.Group> */}


                <Form.Group
                  className="input"
                  as={Col}
                  controlId="CP_WebsiteUrl"
                >
                  <Form.Control
                    name="CP_WebsiteUrl"
                    type="hidden"
                    value={Oban_Whiskey_Domain}
                    runat="server"
                  />
                </Form.Group>

                <Form.Group
                  className="input"
                  as={Col}
                  controlId="HD_IsDefaultFlag"
                >
                  <Form.Control
                    name="HD_IsDefaultFlag"
                    type="hidden"
                    value="1"
                  />

                </Form.Group>
                <Form.Group
                  className="input"
                  as={Col}
                  controlId="HD_IsDefaultFlag"
                >
                  <Form.Control
                    name="HD_IsDefaultFlag"
                    type="hidden"
                    value="1"
                  />
                </Form.Group>
                <Form.Group className="input" controlId="PR_4_367_1_freetext">
                  <Form.Control
                    type="hidden"
                    name="PR_4_367_1_freetext"
                    value=""
                  />
                </Form.Group>
                <Form.Group className="input" controlId="PR_4_92_1">
                  <Form.Control type="hidden" name="PR_4_92_1" value="222" />
                </Form.Group>

                <Form.Group className="input" controlId="">
                  {/* <ReCAPTCHA
                    sitekey="6Ld_GsYlAAAAAG6sAH5EPuAWZDmoOJUnerI3x7Oi"
                    // onChange={onChange}
                  /> */}
                  {/* <GoogleReCaptchaProvider
                    reCaptchaKey="[6Ld_GsYlAAAAAG6sAH5EPuAWZDmoOJUnerI3x7Oi]"
                    // language="[optional_language]"
                    // useRecaptchaNet="[optional_boolean_value]"
                    // useEnterprise="[optional_boolean_value]"
                    scriptProps={{
                      async: false, // optional, default to false,
                      defer: false, // optional, default to false
                      appendTo: 'head', // optional, default to "head", can be "head" or "body",
                      nonce: undefined // optional, default undefined
                    }}
                    container={{ // optional to render inside custom element
                      element: "[required_id_or_htmlelement]",
                      parameters: {
                        badge: '[inline|bottomright|bottomleft]', // optional, default undefined
                        theme: 'dark', // optional, default undefined
                      }
                    }}
                  >
                    {/* <YourApp /> */}
                  {/* </GoogleReCaptchaProvider>,  */}
                </Form.Group>

                <Form.Group className="btnDiv">
                  <Button
                    className="btn"
                    type="submit"
                    onClick={formik.handleSubmit}
                  >
                    Submit
                  </Button>
                </Form.Group>

                <div className="smallText mb-3">
                  <small>
                    Oban values and respects your privacy. You can unsubscribe
                    at any time.
                  </small>
                </div>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
