
import React, { createRef } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Oban_Whiskey_Domain } from "../../constant";

const HeritageAudio = () => {
    const audioPronounce = Oban_Whiskey_Domain + "/audio/pronounce.mp3";
    const audioRef = createRef();

    const playAudio = (event) => {
      if (event.repeat) {
        return;
      }
      audioRef.current.play();
    }

  return (
    <>
         <span className="section-lable">OUR HERITAGE</span>
          <div id="pronounce" className="pronunciation w-inline-block">
            <h3>Our distillery is 208 steps from the sea.</h3>
            <p className="description">We like the air and so does the whisky.</p>
            <div className="pronunciation-guide-text">
              <span>It’s pronounced OH-bin &nbsp;&nbsp;</span>
              <Image
                src={`${Oban_Whiskey_Domain}/assets/images/speaker.png`}
                alt="Play"
                className="play-sound"
                onClick={playAudio}
                height="18"
                style={{cursor: 'pointer'}}
              />
            </div>
            <audio src={audioPronounce} ref={audioRef}/>
            <Link to="/heritage" className="mt-5 d-inline-block">Explore our heritage</Link>
          </div>
    </>
  )
}

export default HeritageAudio