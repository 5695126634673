import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import useWindowDimensions from "../includes/useWindowDimensions";

const NewPeopleVideos = (props) => {

  var getVideos = props.videos;
  const [id, setId] = useState(0);
  const [currentVideo, setCurrentVideo] = useState("");
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const { width } = useWindowDimensions();

  var dynamicProgress1 = new Array(getVideos.length).fill(0);
  const [dynamicProgress, setDynamicProgress] = useState(
    ...Array(getVideos.length).fill(0)
  );

  const handleOnEnded = () => {
    setId((prev) => (id < 5 ? parseInt(prev) + parseInt(1) : 0));
  };

  window.onload = (event) => {
    console.log('page is fully loaded');
    setIsPlaying(false);
  };
 

  useEffect(() => {
    videoRef?.current?.load();
  }, [currentVideo, id]);

  // Update Time
  const onTimeUpdate = (e) => {
    const progress = Math.round(
      (videoRef?.current?.currentTime / videoRef?.current?.duration) * 100
    );
   
    let newState = [...dynamicProgress1];
    newState[id] = progress;
    setDynamicProgress(newState);
  };

  
  // Delay 500
  setTimeout(() => {
    let video = getVideos[id];
    if (video) {
      let videoUrl = video["sources"][1]["src"];
      setCurrentVideo(videoUrl);
    }
  }, 500);

  // Select Items
  const items = (e) => {
    let sid = parseInt(e.target.id);
    setId(sid);
    setIsPlaying(true);
  };

  const [vdisplay, setVdisplay] = useState('0')


  //play pause button
  const handleVideo = (e) => {
    setIsPlaying(!isPlaying);
    if (isPlaying === true) {
      videoRef.current.pause();
      setVdisplay('1')
    } else {
      videoRef.current.play();
      if (width <= 767) {
        setTimeout(() => {
          setVdisplay('0')
        }, 1000);
      }
    }
  }

  return (
    <div className="exploreSection people-slider-wrapper">
    <div className="section-label dark" style={{right: 'auto', left: '30px'}}>STORIES</div>
      <div className="sliderContainer">
        {currentVideo && (
          <div>
            {isPlaying ? (
              <div
                className="pause-button-people"
                id="playButton"
                onClick={handleVideo}
                style={{opacity: vdisplay}}
              ></div>
            ) : (
              <div
                className="play-button-people"
                id="playButton"
                onClick={handleVideo}
                style={{display: setVdisplay}}
              ></div>
            )}

            <video
              autoPlay={true}
              id="myVideo1"
              width="50%"
              ref={videoRef}
              onTimeUpdate={onTimeUpdate}
              crossOrigin="anonymous"
              onEnded={handleOnEnded}
              preload="metadata"
              playsInline
              className="full-video"
            >
              <source src={currentVideo} type="video/mp4" />
            </video>
          </div>
        )}

        <div
          className="progressBarContainer"
        >

          {dynamicProgress &&
            dynamicProgress.map((val, index) => (
              <div key={index} className='d-inlineBlock'>
                {id === index ?
                  <span data-slick-index={index}>
                    <div className="item">
                      <div className="people-name">
                        <div className="fullname-user">
                          <span data-slick-index={index}>
                            {getVideos[index].title}
                          </span>
                          <span data-slick-index={index} className="usern-nm">
                            {getVideos[index].description}
                          </span>
                        </div>
                      </div>
                    </div>
                  </span>
                  : <span className="us-name">
                    {getVideos[index].title}
                  </span>}

                <span
                  id={index}
                  type="button"
                  className={`round-dot-home ${id === index ? "home-video-active" : ""
                    }`}
                  onClick={(e) => items(e)}
                ></span>

                <ProgressBar
                  completed={dynamicProgress[index]}
                  bgColor="#ffffff"
                  borderRadius="0"
                  customLabel=""
                  height="0.5px"
                  transitionDuration={15}
                  className='progressLine'
                />

              </div>
            ))}

        </div>
      </div>
    </div>
  );
};

export default NewPeopleVideos;