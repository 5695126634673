import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Oban_Whiskey_Domain } from "../../constant";
import NewsletterSubmit from "./NewsletterSubmit";
// import { useNavigate } from 'react-router-dom';
import InputMask from "react-input-mask";


// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import MultiDropdown from "../includes/Multiselect";

const ThankYou = ({ }) => {
    // const navigate = useNavigate();

    const parse = require("html-react-parser");
    const location = useLocation(); // React Hook

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);





    return (
        <>
            <Helmet>
                <title>Thank You!</title>
                <meta name="description" content="metaDescription" />
                <meta property="og:title" content="title" />
                <meta property="og:description" content="" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="test" />
                <meta
                    property="og:image"
                    content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
                />
                <meta name="twitter:card" content="" />
                <meta name="twitter:url" content="https://www.obanwhisky.comform" />
                <meta name="twitter:title" content="title" />
                <meta
                    name="twitter:image"
                    content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
                />
            </Helmet>
            <main className="main newsLetter-page">
                <section className="newsLetter-container newsLetter">
                    <Container>
                        <div className="justify-content-center stayTouch">
                            <div className="d-flex justify-content-center text-align-center">
                                <h1 className="text-dark"></h1>
                            </div>
                            {/* <p className="text-center">
                  Sign up today for exclusive updates from our team, direct from
                  the distillery.
                </p> */}
                            {/* <p className="text-center">Fields marked with * are required</p> */}
                        </div>


                    </Container>


                </section>

            </main>
            <div className="content-center mt-5">
                <div className="d-flex justify-content-center text-align-center">
                    <h1 className="text-dark">Thank You!</h1>

                </div>
            </div>
            <div className="d-flex justify-content-center text-align-center">
                <p>Our team will get in touch with you shortly.</p>
            </div>

        </>
    );
};

export default ThankYou;
