import React from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import Iframe from "react-iframe";
import { Helmet } from 'react-helmet';
import { Oban_Whiskey_Domain } from "../../constant";
import { useEffect } from "react";

const ShopOnline = ({shopOnlineKey}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="main shop-online">
      <Helmet>
          <title>{shopOnlineKey.title}</title>
          <meta name="description" content={shopOnlineKey.metaDescription} />
          <meta property="og:title" content={shopOnlineKey.ogTitle} />
          <meta
            property="og:description"
            content={shopOnlineKey.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${Oban_Whiskey_Domain + shopOnlineKey.url}`}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={shopOnlineKey.metaDescription} />
          <meta
            name="twitter:url"
            content={`${Oban_Whiskey_Domain + shopOnlineKey.url}`}
          />
          <meta name="twitter:title" content={shopOnlineKey.title} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
     

          <section className="content find-near-me">
          <Container>
            <div className="where-to-buy-Headline">
              <h1 className="text-white-dark mb-5">Shop Online</h1>
            </div>
            <div>
              <Iframe
                url="https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=12037990"
                width="100%"
                height="800px"
                id="wtbIframe"
                className="wtbIframe"
                overflow="hidden"
                minHeight="20px"
              />
            </div>
            </Container>
          </section>
   
          <section className="home-last-section">
            <Container>
              <Row className="row">
                <Col md={6} style={{position: 'relative'}}>
                  <div className="text-content">
                    <h3 className="content-head">
                    You can find us where the Highlands meet the Islands.
                    </h3>
                    <p>
                    The Oban Distillery Visitor Center — Winner of the Association of Scottish Visitor Attractions’ Best Visitor Experience award in 2017 — offers tours and tastings for a range of budgets and interests.
                    </p>
                    <p>
                    Pay us a visit, hear our stories, and witness how one of the world’s oldest and finest whiskies is produced first hand.
                    </p>
                    <div className="cta-wrapper">
                      <a href="https://www.malts.com/en-gb/distilleries/oban/tours" className="inline-link" target="_blank">
                      Visit our distillery
                      </a>
                    </div>
                  </div>
                </Col>
                
                <Col sx={6}>
                  <div className="footer-image home">
                    <Image
                      src={`${Oban_Whiskey_Domain}/assets/images/buy_bottom-p-500.jpeg`}
                      width="450px"
                      height="588px"
                      alt="home-bottom"
                      className="image-footer-responsive"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
      </main>
    </>
  );
};

export default ShopOnline;