import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap";
import { Oban_Whiskey_Domain } from "../../constant";
const obanFactJsonAPI = Oban_Whiskey_Domain + "/data/obanFacts.json";


const FactsSection = () => {
    const [key, setKey] = useState("All");
    const [obanFactsData, setObanFactsData] = useState([]);
    const [factsData, setFactData] = useState([]);
    const [currentNo, setCurrentNo] = useState(0);
    const [imageFacts, setImage] = useState(false);
    const isMountedRef = useRef(false);

    // var FactOban; // Using Tab Catagory
    const getData = async () => {
        await fetch(obanFactJsonAPI, {
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setObanFactsData(myJson);
                setFactData(myJson);
                setImage(myJson[currentNo].img);
            });
  };

    useEffect(() => {
        if (!isMountedRef.current) {
            isMountedRef.current = true;
            getData();
        }
    }, []);

    if (obanFactsData) {
        // FactOban = obanFactsData;
    var newCat = ["All"];
    var  catsArray= [...new Set(obanFactsData.map((catVal) => catVal.category))];
    var cats = newCat.concat(catsArray);
    }

    // Tab Select
    const tabData = (param) => {
        let filtered;
        setKey(param);
    if (param === "All") {
            filtered = obanFactsData;
        } else {
            filtered = obanFactsData.filter((obj) => obj.category === param);

        }

        setFactData(filtered);
        setImage(filtered[0].img);
        setCurrentNo(0);
  };

    // Facts title click
    const factsTitle = (index) => {
        setImage(factsData[index].img);
        setCurrentNo(index);
  };

    // Search Facts Title
    const searchChange = (event) => {
        let filtered;
    if (event.target.value === "") {
            filtered = obanFactsData;
      setKey("All");
        } else {
      filtered = obanFactsData.filter(
       (obj) => obj.title.toLowerCase().includes(event.target.value.toLowerCase()))    
      setKey("");
        }
        if (filtered.length > 0) {
            
            setFactData(filtered);
            setImage(filtered[0].img);
            setCurrentNo(0);
        }
  };

    return (
        <>
      <Tabs
        defaultActiveKey="All"
        id="uncontrolled-tab-example"
        className="mb-3"
        activeKey={key}
        onSelect={(k) => tabData(k)}
      >
        {cats.map((item, index) => (
                        <Tab eventKey={item} title={item} key={index} />

                    ))

                }
            </Tabs>
            <div className="d-lg-flex b-block tabData">
                <div className='w-50'>
                    <div className="conterDiv">
                        <div className="image-counter d-flex">
                            <div className="imageNum">
                                <span id='imageCurentNo'>{currentNo + 1}</span>
                            </div>
                            <div className="borderDiv">|</div>
                            <div className="imageNum">
                                <span id='imagetotalNo'>
                                    {factsData.length}
                                </span>
                            </div>
                        </div>
                        <Image
                            src={`${Oban_Whiskey_Domain}/assets/images/facts/${imageFacts}`}
                            alt='image'
                            className="img-fluid d-lg-block d-none"
                        />
                    </div>
                </div>
                <div className="w-50 factsDiv">
                    <div className="factsDataTitle container m-0">
                        <Row>
                            <Col xs="5">
                                <h3 className="m-0 ps-4">Facts</h3>
                            </Col>
                            <Col xs="7" className='searchboxDiv'>
                                <input
                                    type="text"
                                    name="message"
                                    id="message"
                                    className="searchbox"
                                    placeholder="SEARCH FOR..."
                                    onKeyUp={searchChange}
                                />
                            </Col>
                        </Row>
                    </div>

                    <Container className="factsData p-0" >
                        {
                            factsData && factsData.map((item, index) => (
                                <div key={index} id={index} className={`factsItem ${index === currentNo?'active':''}`} onClick={(k) => factsTitle(index)}>
                                    <div className='factsDesc'>
                                        <div>
                                            <h5 className="title">{item.title}</h5>
                                            <p className="fact-description">{item.fact}</p>
                                        </div>
                                    </div>
                                    <div className='factsImg' style={{backgroundImage: `url(${Oban_Whiskey_Domain}/assets/images/facts/${item.img})`}}>
                                        {/* <Image
                                            src={`${Oban_Whiskey_Domain}/assets/images/facts/${item.img}`}
                                            alt={item.category}
                                            className="img-fluid"
                                        /> */}
                                    </div>
                                </div>
                            ))
                        }
                    </Container>
                    <div className="gradient-cutoff">
                        <Image src="/assets/images/scroll-icon.svg" alt="Scroll" className="scroll-icon" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FactsSection


